@font-face {
	font-family: 'Encode Sans Semi Condensed';
	font-style: normal;
	font-weight: bold;
	src: url('./fonts/EncodeSansSemiCondensed-Bold.ttf') format('truetype');
}
@font-face {
	font-family: 'Encode Sans Semi Condensed';
	font-style: normal;
	font-weight: normal;
	src: url('./fonts/EncodeSansSemiCondensed-Regular.ttf') format('truetype');
}

html,
body,
#root {
	color: #2e6eff;
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	position: relative;
	box-sizing: border-box;
	font-family: 'Encode Sans Semi Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

a {
	text-decoration: none;
}
